$accent: #023c5b;
$primary: #023c5b;
$font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

// Data management
@import "~@progress/kendo-theme-bootstrap/scss/grid";

// Editors
@import "~@progress/kendo-theme-bootstrap/scss/editor";
@import "~@progress/kendo-theme-bootstrap/scss/upload"; // upload depends on editor

.k-widget .ui.grid {
    // semantic grids won't take 100% width without when inside kendo components
    flex-grow: 1;
}

.k-pager .k-widget .k-grid-pager {
    display: "flex";
}

.k-grid-less-padding th,
.k-grid-less-padding td {
    padding: 0.3rem !important;
    font-size: 11px !important;
}
.k-grid-no-hover tr:hover {
    background: transparent !important;
}
